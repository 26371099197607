export const posts = [
  { name: "Vice President", value: "VP" },
  { name: "General Secretary Technology", value: "Gsec_Tech" },
  { name: "General Secretary Social And Cultural", value: "Gsec_Socult" },
  { name: "General Secretary Sports And Games", value: "Gsec_Sports" },
  { name: "General Secretary Welfare(Male)", value: "Gsec_WM" },
  { name: "General Secretary Welfare(Female)", value: "Gsec_WF" },
  // { name: "Secretaries", value: "Secy" },
];
export const secyPosts = [
  { name: "Football", value: "Football" },
  { name: "Cricket", value: "Cricket" },
  { name: "Hocvalue", value: "Hocvalue" },
  { name: "Tennis", value: "Tennis" },
  { name: "Indoor Games", value: "IndoorGames" },
  { name: "Volleyball", value: "Volleyball" },
  { name: "Basketball", value: "Basketball" },
  { name: "Badminton", value: "Badminton" },
  { name: "Gymnasium & Weightlifting", value: "Gymnasium" },
  { name: "Athletics", value: "Athletics" },
  { name: "Aquatics", value: "Aquatics" },
  { name: "Squash", value: "Squash" },
  { name: "Entertainment", value: "Entertainment" },
  { name: "Dramatics", value: "Dramatics" },
  { name: "Journal", value: "Journal" },
  { name: "Literary", value: "Literary" },
  { name: "Film & Photography", value: "Film" },
  { name: "Fine Arts & Modelling", value: "FineArts" },
  { name: "Knowledge Cup", value: "KnowledgeCup" },
  { name: "Application Cup", value: "ApplicationCup" },
  { name: "Innovation Cup", value: "InnovationCup" },
  { name: "Strategy Cup", value: "StrategyCup" },
  { name: "Academic Help", value: "Ahelp" },
  { name: "Community & Crisis", value: "CnC" },
];
export const candidates = {
  VP: [
    {
      RollNo: "22BT10031",
      Description: "BT '26",
      Name: "Sudhagani Praneeth Kumar",
      Contact: "XXX XXX XXX",
      Hall: "",
      Email: "",
      img: "Praneeth_VP.jpg",
      Proposal: "",
      ProposalLink: "22BT10031.pdf",
      FacebookLink: ""
    },
    {
      RollNo: "22BT3FP36",
      Description: "BT '27",
      Name: "Sreelekshmi Kishore",
      Contact: "XXX XXX XXX",
      Hall: "",
      Email: "",
      img: "Sreelekshmi_VP.jpg",
      Proposal: "",
      ProposalLink: "22BT3FP36.pdf",
      FacebookLink: ""
    },
    {
      RollNo: "22CH3EP23",
      Description: "CH '27",
      Name: "Amish Bhatnagar",
      Contact: "XXX XXX XXX",
      Hall: "",
      Email: "",
      img: "Amish_VP.jpg",
      Proposal: "",
      ProposalLink: "22CH3EP23.pdf",
      FacebookLink: ""
    },
    {
      RollNo: "22ME3EP10",
      Description: "ME '27",
      Name: "Arman Barik",
      Contact: "XXX XXX XXX",
      Hall: "",
      Email: "",
      img: "Arman_VP.jpg",
      Proposal: "",
      ProposalLink: "22ME3EP10.pdf",
      FacebookLink: ""
    }
  ],
  Gsec_Sports: [
    {
      RollNo: "23AG10038",
      Description: "AG '27",
      Name: "Serampally Sidharth Reddy",
      Contact: "XXX XXX XXX",
      Hall: "",
      Email: "",
      Proposal: "",
      ProposalLink: "23AG10038.pdf",
      FacebookLink: "",
      img: "Sidharth_Sports.png"
    },
    {
      RollNo: "23ME30073",
      Description: "ME '28",
      Name: "Zalavadiya Dishil",
      Contact: "XXX XXX XXX",
      Hall: "",
      Email: "",
      Proposal: "",
      ProposalLink: "23ME30073.pdf",
      FacebookLink: "",
      img: ""
    },
    {
      RollNo: "23NA10027",
      Description: "NA '27",
      Name: "Namanya Pant",
      Contact: "XXX XXX XXX",
      Hall: "",
      Email: "",
      Proposal: "",
      ProposalLink: "23NA10027.pdf",
      FacebookLink: "",
      img: "Namanya_Sports.png"
    },
    {
      RollNo: "23SD11008",
      Description: "SD '27",
      Name: "Mayank Singh",
      Contact: "XXX XXX XXX",
      Hall: "",
      Email: "",
      Proposal: "",
      ProposalLink: "23SD11008.pdf",
      FacebookLink: "",
      img: ""
    }
  ],
  Gsec_Socult: [
    {
      RollNo: "23AG10011",
      Description: "AG '27",
      Name: "Archit Kumar Mawandia",
      Contact: "XXX XXX XXX",
      Hall: "",
      Email: "",
      Proposal: "",
      ProposalLink: "23AG10011.pdf",
      FacebookLink: "",
      img: ""
    },
    {
      RollNo: "23ME10047",
      Description: "ME '27",
      Name: "Mantra Malpani",
      Contact: "XXX XXX XXX",
      Hall: "",
      Email: "",
      Proposal: "",
      ProposalLink: "23ME10047.pdf",
      FacebookLink: "",
      img: "Mantra_Socult.jpg"
    },
    {
      RollNo: "23MF3IM16",
      Description: "MF '28",
      Name: "Priyanshu Bhooshan",
      Contact: "XXX XXX XXX",
      Hall: "",
      Email: "",
      Proposal: "",
      ProposalLink: "23MF3IM16.pdf",
      FacebookLink: "",
      img: ""
    }
  ],
  Gsec_Tech: [
    {
      RollNo: "23HS10065",
      Description: "HS '27",
      Name: "Pratham Agarwal",
      Contact: "XXX XXX XXX",
      Hall: "",
      Email: "",
      Proposal: "",
      ProposalLink: "23HS10065.pdf",
      FacebookLink: "",
      img: "Pratham_Tech.jpg"
    },
    {
      RollNo: "23IM10049",
      Description: "IM '27",
      Name: "Prem Pastagia",
      Contact: "XXX XXX XXX",
      Hall: "",
      Email: "",
      Proposal: "",
      ProposalLink: "23IM10049.pdf",
      FacebookLink: "",
      img: "Prem_Tech.jpg"
    },
    {
      RollNo: "23ME30086",
      Description: "ME '28",
      Name: "Piyush Jaiswal",
      Contact: "XXX XXX XXX",
      Hall: "",
      Email: "",
      Proposal: "",
      ProposalLink: "",
      FacebookLink: "",
      img: ""
    }
  ],
  Gsec_WM: [
    {
      RollNo: "23IM10040",
      Description: "IM '27",
      Name: "Tanush B Agarwal",
      Contact: "XXX XXX XXX",
      Hall: "",
      Email: "",
      Proposal: "",
      ProposalLink: "23IM10040.pdf",
      FacebookLink: "",
      img: "Tanush_Welfare.jpg"
    },
    {
      RollNo: "23SD11001",
      Description: "SD '27",
      Name: "Krish Varshney",
      Contact: "XXX XXX XXX",
      Hall: "",
      Email: "",
      Proposal: "",
      ProposalLink: "23SD11001.pdf",
      FacebookLink: "",
      img: ""
    }
  ],
  Gsec_WF: [
    {
      RollNo: "23IM30008",
      Description: "IM '28",
      Name: "Dnyaneshwari Ghare",
      Contact: "XXX XXX XXX",
      Hall: "",
      Email: "",
      Proposal: "",
      ProposalLink: "23IM30008.pdf",
      FacebookLink: "",
      img: ""
    }
  ]
  // Secy_Aquatics: [
  //   {
  //     RollNo: "21AE10037",
  //     Description: "AE '25",
  //     Name: "Solanki Jainish Shailesh",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RPH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21AR10009",
  //     Description: "AR '25",
  //     Name: "Chitikesh Sai Naga Raju ",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21CH10045",
  //     Description: "CH '25",
  //     Name: "Pravalika Das",
  //     Contact: "XXX XXX XXX",
  //     Hall: "SN/IG",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21CY10037",
  //     Description: "CY '25",
  //     Name: "Siddharth Gupta",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21ME10022",
  //     Description: "ME '25",
  //     Name: "Bhuvan Suresh Jambhulkar",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RKH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MI33024",
  //     Description: "MI '26",
  //     Name: "Unadkat Vivek Shyamal",
  //     Contact: "XXX XXX XXX",
  //     Hall: "NHR",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_Athletics: [
  //   {
  //     RollNo: "21CE10026",
  //     Description: "CE '25",
  //     Name: "Harsh Kumar",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21CH30031",
  //     Description: "CH '26",
  //     Name: "Shreya Mishra",
  //     Contact: "XXX XXX XXX",
  //     Hall: "MTH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MF10008",
  //     Description: "ME '25",
  //     Name: "Anumothu Ankit",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21NA10042",
  //     Description: "NA '25",
  //     Name: "Vivek Pandey",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RKH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21PH10020",
  //     Description: "PH '25",
  //     Name: "Keshav Kaushal",
  //     Contact: "XXX XXX XXX",
  //     Hall: "MMH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_Badminton: [
  //   {
  //     RollNo: "21AG10021",
  //     Description: "AG '25",
  //     Name: "Madhav Kumar",
  //     Contact: "XXX XXX XXX",
  //     Hall: "",
  //     Email: "MMH",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21AG30015",
  //     Description: "AG '26",
  //     Name: "Divyansh Singh Rana",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RPH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21BT10018",
  //     Description: "BT '25",
  //     Name: "Kislay",
  //     Contact: "XXX XXX XXX",
  //     Hall: "AZD",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21CH10015",
  //     Description: "CH '25",
  //     Name: "Bharat Kabra",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21CH10047",
  //     Description: "CH '25",
  //     Name: "Qureshi Shoaib Irfan Ahmed",
  //     Contact: "XXX XXX XXX",
  //     Hall: "PAT",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21HS10007",
  //     Description: "HS '25",
  //     Name: "Aditya Ravte",
  //     Contact: "XXX XXX XXX",
  //     Hall: "PAT",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21IE10007",
  //     Description: "EE '25",
  //     Name: "Atul Kumar",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RPH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21ME30053",
  //     Description: "ME '26",
  //     Name: "Rishi Sandeep Dhoble",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RPH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MI31026",
  //     Description: "MI '26",
  //     Name: "Bhuneshwar Netam",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MT10023",
  //     Description: "MT '25",
  //     Name: "Jay Singh",
  //     Contact: "XXX XXX XXX",
  //     Hall: "MMH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_Basketball: [
  //   {
  //     RollNo: "21CS10030",
  //     Description: "CS '25",
  //     Name: "Harsh Sharma",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21HS10006",
  //     Description: "HS '25",
  //     Name: "Aditya Raj",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21PH10007",
  //     Description: "PH '25",
  //     Name: "Bhagyashree Gupta",
  //     Contact: "XXX XXX XXX",
  //     Hall: "SN/IG",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_Cricket: [
  //   {
  //     RollNo: "21AR10035",
  //     Description: "AR '25",
  //     Name: "Syed Ahmad Hasan",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RPH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21EC10011",
  //     Description: "EC '25",
  //     Name: "Banisetty Hema Sai Sagar ",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21IM10021",
  //     Description: "IM '25",
  //     Name: "Purushottam Kumar",
  //     Contact: "XXX XXX XXX",
  //     Hall: "AZD",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21ME10008",
  //     Description: "ME '25",
  //     Name: "Aditya Yash Raj ",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21ME30068",
  //     Description: "ME '26",
  //     Name: "Tejavathu Eshwar",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MI10021",
  //     Description: "MI '25",
  //     Name: "Hariom",
  //     Contact: "XXX XXX XXX",
  //     Hall: "AZD",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MI10044",
  //     Description: "MI '25",
  //     Name: "Rupesh",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RPH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MI33008",
  //     Description: "MI '26",
  //     Name: "Bhoumik Mhatre",
  //     Contact: "XXX XXX XXX",
  //     Hall: "AZD",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MI33023",
  //     Description: "MI '26",
  //     Name: "Sumedh Deshkar",
  //     Contact: "XXX XXX XXX",
  //     Hall: "PAT",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MT30020",
  //     Description: "MT '26",
  //     Name: "Nikhil Gusaiwal",
  //     Contact: "XXX XXX XXX",
  //     Hall: "NHR",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_Football: [
  //   {
  //     RollNo: "21AE10032",
  //     Description: "AE '25",
  //     Name: "Rajat Raj Mishra",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21AR10034",
  //     Description: "AR '25",
  //     Name: "Sumeet Dubey",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RPH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MI33010",
  //     Description: "MI '26",
  //     Name: "Dipendra Kumar Gupta",
  //     Contact: "XXX XXX XXX",
  //     Hall: "MMH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_Gymnasium: [
  //   {
  //     RollNo: "21AG10036",
  //     Description: "AG '25",
  //     Name: "Shaswata Kangsa Banik",
  //     Contact: "XXX XXX XXX",
  //     Hall: "MMH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MA10032",
  //     Description: "MA '25",
  //     Name: "Manaswi Raj",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RPH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MT10041",
  //     Description: "MT '25",
  //     Name: "Rohit Gautam Bhalerao",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RKH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21NA30011",
  //     Description: "NA '26",
  //     Name: "Kammampati Charan Teja",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21NA30014",
  //     Description: "NA '26",
  //     Name: "Lade Akshat Dilip",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RKH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_IndoorGames: [
  //   {
  //     RollNo: "21BT10024",
  //     Description: "BT '25",
  //     Name: "Rohan Rajendra Barsagade",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RKH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MI31001",
  //     Description: "MI '26",
  //     Name: "Aadit",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RPH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MT30009",
  //     Description: "MT '26",
  //     Name: "Dikshant Sharma",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_Squash: [
  //   {
  //     RollNo: "21AR10029",
  //     Description: "AR '25",
  //     Name: "Rupangshu Banik",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21IM30004",
  //     Description: "IM '26",
  //     Name: "Archie Avirati",
  //     Contact: "XXX XXX XXX",
  //     Hall: "MTH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_Tennis: [
  //   {
  //     RollNo: "21BT30028",
  //     Description: "BT '26",
  //     Name: "Soujanya Nayak",
  //     Contact: "XXX XXX XXX",
  //     Hall: "PAT",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_Volleyball: [
  //   {
  //     RollNo: "21BT30016",
  //     Description: "BT '26",
  //     Name: "Mayureshwar Rajendra Somavanshi",
  //     Contact: "XXX XXX XXX",
  //     Hall: "MMH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21CS10050",
  //     Description: "CS '25",
  //     Name: "Parvathaneni Venkatsai Mokshith",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21EE10028",
  //     Description: "EE '25",
  //     Name: "Gagan Meena ",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RKH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21EE30016",
  //     Description: "EE '26",
  //     Name: "Naveen Kumar",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21HS10044",
  //     Description: "HS '25",
  //     Name: "Sagar Kumar",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21IE10001",
  //     Description: "EE '25",
  //     Name: "Abhraneel Ghosh",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21IE10022",
  //     Description: "EE '25",
  //     Name: "M Vaishnavi ",
  //     Contact: "XXX XXX XXX",
  //     Hall: "MTH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_Entertainment: [
  //   {
  //     RollNo: "21CH30022",
  //     Description: "CH '26",
  //     Name: "Pranjal Rathore",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21GG10039",
  //     Description: "GG '25",
  //     Name: "Siddharth Pandey",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RKH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21IM10023",
  //     Description: "IM '25",
  //     Name: "Rakesh Rulaniya",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RPH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MA10063",
  //     Description: "MA '25",
  //     Name: "Thadana Greeshma",
  //     Contact: "XXX XXX XXX",
  //     Hall: "NVH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_Dramatics: [
  //   {
  //     RollNo: "21HS10030",
  //     Description: "HS '25",
  //     Name: "Neeraj Patel ",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MA10060",
  //     Description: "MA '25",
  //     Name: "Shubham Prakash",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RKH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21ME30050",
  //     Description: "ME '26",
  //     Name: "Potluri Sriya",
  //     Contact: "XXX XXX XXX",
  //     Hall: "SN/IG",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_Journal: [
  //   {
  //     RollNo: "21AG30013",
  //     Description: "AG '26",
  //     Name: "Digvijay Singh",
  //     Contact: "XXX XXX XXX",
  //     Hall: "NHR",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21CY10006",
  //     Description: "CY '25",
  //     Name: "Anushka Jain",
  //     Contact: "XXX XXX XXX",
  //     Hall: "MTH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MF10006",
  //     Description: "ME '25",
  //     Name: "Anamika Tiwary",
  //     Contact: "XXX XXX XXX",
  //     Hall: "SN/IG",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_Literary: [
  //   {
  //     RollNo: "21CH10043",
  //     Description: "CH '25",
  //     Name: "Mrinalini Singh",
  //     Contact: "XXX XXX XXX",
  //     Hall: "MTH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21EC10058",
  //     Description: "EC '25",
  //     Name: "S S V K S S Jyothiraditya",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_Film: [
  //   {
  //     RollNo: "21CE10082",
  //     Description: "CE '25",
  //     Name: "Vishal Deora",
  //     Contact: "XXX XXX XXX",
  //     Hall: "PAT",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21HS10026",
  //     Description: "HS '25",
  //     Name: "Manish Kumar",
  //     Contact: "XXX XXX XXX",
  //     Hall: "AZD",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21ME10033",
  //     Description: "ME '25",
  //     Name: "Emmadi Vamshi Krishna",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MI10049",
  //     Description: "MI '25",
  //     Name: "Thakre Himanshoo Rajeshkumar",
  //     Contact: "XXX XXX XXX",
  //     Hall: "NHR",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21NA30033",
  //     Description: "NA '26",
  //     Name: "Swagoto Surjo  Dutta",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_FineArts: [
  //   {
  //     RollNo: "21CY10010",
  //     Description: "CY '25",
  //     Name: "Chirag Maru",
  //     Contact: "XXX XXX XXX",
  //     Hall: "AZD",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21IM10012",
  //     Description: "IM '25",
  //     Name: "Chimata Bhavya Charitha",
  //     Contact: "XXX XXX XXX",
  //     Hall: "SN/IG",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MA10002",
  //     Description: "MA '25",
  //     Name: "Abhinaw Anand",
  //     Contact: "XXX XXX XXX",
  //     Hall: "NHR",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MI10040",
  //     Description: "MI '25",
  //     Name: "Pratik Pranjal",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RPH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21NA10011",
  //     Description: "NA '25",
  //     Name: "Anusha Hitendra Borole",
  //     Contact: "XXX XXX XXX",
  //     Hall: "MTH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21NA10021",
  //     Description: "NA '25",
  //     Name: "Mayank Kulhari",
  //     Contact: "XXX XXX XXX",
  //     Hall: "PAT",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_KnowledgeCup: [
  //   {
  //     RollNo: "21AR10025",
  //     Description: "AR '25",
  //     Name: "Prerana Singh ",
  //     Contact: "XXX XXX XXX",
  //     Hall: "NVH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21HS20001",
  //     Description: "HS '25",
  //     Name: "Amey",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_ApplicationCup: [
  //   {
  //     RollNo: "21CS30016",
  //     Description: "CS '26",
  //     Name: "Chityala Raviteja",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21EX10029",
  //     Description: "GG '25",
  //     Name: "Saurabh Jain",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RPH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21HS10017",
  //     Description: "HS '25",
  //     Name: "Ayush Kumar",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RPH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21IM30003",
  //     Description: "IM '26",
  //     Name: "Aishwary Patel",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_InnovationCup: [
  //   {
  //     RollNo: "21AR10039",
  //     Description: "AR '25",
  //     Name: "Ayush Raj",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21EE30022",
  //     Description: "EE '26",
  //     Name: "Sawan Kumar Singh",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21EX10020",
  //     Description: "GG '25",
  //     Name: "Mayank Sony",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MA10027",
  //     Description: "MA '25",
  //     Name: "Kumar Gaurav",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RKH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_StrategyCup: [
  //   {
  //     RollNo: "21AR10040",
  //     Description: "AR '25",
  //     Name: "Benjamin Doley",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_Ahelp: [
  //   {
  //     RollNo: "21AR10022",
  //     Description: "AR '25",
  //     Name: "Palak Agarwal",
  //     Contact: "XXX XXX XXX",
  //     Hall: "SN/IG",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21EC30033",
  //     Description: "EC '26",
  //     Name: "Medavarapu Atchutha Rama Murthy",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21EE30028",
  //     Description: "EE '26",
  //     Name: "Sumit Singh",
  //     Contact: "XXX XXX XXX",
  //     Hall: "PAT",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "Shahil Gupta",
  //     Description: "ME '25",
  //     Name: "Shahil Gupta",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21NA10018",
  //     Description: "NA '25",
  //     Name: "Krish Agarwal",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_CnC: [
  //   {
  //     RollNo: "21CE30024",
  //     Description: "CE '26",
  //     Name: "Ravi Katariya",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21EX10002",
  //     Description: "GG '25",
  //     Name: "Abhisar Upadhyay",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21IE10044",
  //     Description: "EE '25",
  //     Name: "Yaman Meena ",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21MF10011",
  //     Description: "ME '25",
  //     Name: "Ayush Gupta",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RKH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
  // Secy_Hocvalue: [
  //   {
  //     RollNo: "21CE10049",
  //     Description: "CE '25",
  //     Name: "Priyanshu Singh",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21CE30011",
  //     Description: "CE '26",
  //     Name: "Ayush Agrawal",
  //     Contact: "XXX XXX XXX",
  //     Hall: "RPH",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  //   {
  //     RollNo: "21HS10004",
  //     Description: "HS '25",
  //     Name: "Abhishek Tiwari",
  //     Contact: "XXX XXX XXX",
  //     Hall: "LBS",
  //     Email: "",
  //     Proposal: "",
  //     ProposalLink: "",
  //     FacebookLink: "",
  //   },
  // ],
};
